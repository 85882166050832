
import { defineComponent } from 'vue'
import { Form, Field } from 'vee-validate'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
import { Vue3Lottie } from 'vue3-lottie'
import Select from '@/components/atoms/CustomSelect.vue'
import PlusInventory from '@/assets/svg/plus-inventory.svg?inline'
import EmptyPictyre from '@/assets/svg/empty-pictyre.svg?inline'
import Edit from '@/assets/svg/edit.svg?inline'
import Delete from '@/assets/svg/delete.svg?inline'
import Plus from '@/assets/svg/plus.svg?inline'
import InventoryDefoult from '@/assets/svg/inventory-defoult.svg?inline'
import Minus from '@/assets/svg/minus.svg?inline'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import authHeader from '@/services/auth-header'

import Popup from '@/components/molecules/inventory/Popup.vue'

export default defineComponent({
  name: 'CreateUnitStepInventorySection',
  data () {
    return {
      buildingId: '',
      unitId: '',
      errorUnit: null,
      unitData: {},
      isLoading: true,
      selectRoom: [],
      roomName: 'Select room *',
      dataRoom: [],
      isRoomTemplate: false,
      isCompliteRoomTempalte: false,
      isEditRoomTitle: false,
      isNewInventoryPopup: false,
      newRoomName: '',
      isRoomPhotoPopup: false,
      isAddOtherRoomPopup: false,
      newOtherRoome: '',
      isPreview: false,
      file: null,
      inventoryFile: null,
      imagePreview: null,
      imageInventoryPreview: null,
      isInventoryPhotoPopup: false,
      inventoryList: {},
      newRoomId: null,
      newOtherInventory: '',
      indexEditRoomName: null,
      roomPhotoId: null,
      attachmentRoomId: null,
      inventoryId: null,
      isDescriptionPopup: false,
      inventoryDescription: '',
      inventoryName: '',
      imgPopupRoom: null,
      parentRoomId: null,
      otherInventoryId: null,
      isModalDeleteRoom: false,
      isModalDeleteInventory: false,
      errorRoom: null,
      errorInventory: null,
      error: null,
      errorRoomPhoto: null,
      isPreviewInventoryPhoto: false,
      imgPopupInventory: null
    }
  },
  mounted () {
    this.buildingId = this.$route.query.buildingId
    this.unitId = this.$route.query.unitId
    this.getUnit()
    this.getRooms()
  },
  components: {
    AlertBlock,
    Field,
    Form,
    LottieAnimation: Vue3Lottie,
    PlusInventory,
    Select,
    EmptyPictyre,
    Edit,
    Delete,
    Plus,
    InventoryDefoult,
    Minus,
    DefaultPopup,
    Popup
  },
  methods: {
    getUnit () {
      this.$store.dispatch('property/getUnit', this.unitId).then(
        (res) => {
          this.selectRoom = []
          this.unitData = res.data
          this.isLoading = false
          for (const item of this.unitData.draft.inventory) {
            this.selectRoom.push({
              name: item.name,
              id: item.id,
              attachment: item?.attachment?.url,
              attachmentId: item?.attachment?.id,
              inventoryItems: item?.inventory_items,
              inventoryDescription: item?.description,
              parentRoomId: item?.parent_room_id,
              defaultInventoryItems: item?.default_inventory_items
            })
            if (item.parent_room_id) {
              this.getInventory(item.parent_room_id)
            }
          }
          if (this.selectRoom.length > 0) {
            this.isRoomTemplate = true
          }
        },
        (error) => {
          this.error = error.response.data.message
          this.isLoading = false
        }
      )
    },
    getRooms () {
      this.$store.dispatch('inventory/getRooms').then(
        (res) => {
          this.dataRoom = res.data
        },
        (error) => {
          this.error = error.response.data.message
        }
      )
    },
    getInventory (data) {
      this.isLoading = true
      this.$store.dispatch('inventory/getInventory', data).then(
        (res) => {
          this.inventoryList[this.parentRoomId] = res.data
          this.isLoading = false
        },
        (error) => {
          this.error = error.response.data.message
          this.isLoading = false
        }
      )
    },
    goToSecondStep () {
      this.$router.push(`/dashboard/create-unit-step-three?buildingId=${this.buildingId}&unitId=${this.unitId}`)
    },
    handleOptionSelected (selectedOption) {
      this.newRoomId = selectedOption.id
      this.parentRoomId = selectedOption.id
      this.newOtherRoome = selectedOption.name
      this.imagePreview = null
      this.addOtherRoom()
    },
    addInventory (roomId, name) {
      this.isCompliteRoomTempalte = true
      this.addOtherInventory(roomId, name)
    },
    increment (count, roomId, inventoryId, name) {
      const quantity = count + 1
      this.roomId = roomId
      this.inventoryId = inventoryId
      this.inventoryName = name
      this.editInventory(quantity)
    },
    decrement (count, roomId, inventoryId, name) {
      if (count > 1) {
        const quantity = count - 1
        this.roomId = roomId
        this.inventoryId = inventoryId
        this.inventoryName = name
        this.editInventory(quantity)
      }
    },
    addOtherRoom (data) {
      this.isLoading = true
      data = {
        unitId: this.unitId,
        name: this.newOtherRoome,
        parent_room_id: this.parentRoomId
      }
      this.$store.dispatch('inventory/addOtherRoom', data).then(
        (res) => {
          this.isAddOtherRoomPopup = false
          this.isRoomTemplate = true
          if (this.newRoomId === null) {
            this.getInventory(res.data.id)
          } else this.getInventory(this.newRoomId)
          this.selectRoom.push({
            id: res.data.id,
            name: this.newOtherRoome
          })
          this.newRoomId = null
          this.parentRoomId = null
          this.newOtherRoome = ''
          this.getUnit()
        },
        (error) => {
          this.error = error.response.data.errors
          this.isLoading = false
        }
      )
    },
    addOtherInventory (roomId, roomName) {
      this.isLoading = true
      if (this.otherInventoryId) {
        this.newRoomId = this.otherInventoryId
      }
      if (this.newRoomId === null) {
        this.newRoomId = roomId
      }
      let newRoomName = roomName
      if (!newRoomName) {
        newRoomName = this.newOtherInventory
      }
      const data = {
        unitId: this.unitId,
        roomId: this.newRoomId,
        name: newRoomName
      }
      this.$store.dispatch('inventory/addOtherInventory', data).then(
        (res) => {
          this.isNewInventoryPopup = false
          this.isCompliteRoomTempalte = true
          this.newOtherInventory = ''
          this.newRoomId = null
          this.otherInventoryId = null
          this.getUnit()
        },
        (error) => {
          this.error = error.response.data.errors
          this.isLoading = false
        }
      )
    },
    editInventory (count) {
      this.isLoading = true
      const data = {
        unitId: this.unitId,
        roomId: this.roomId,
        inventoryId: this.inventoryId,
        name: this.inventoryName,
        quantity: count
      }
      this.$store.dispatch('inventory/editInventory', data).then(
        (res) => {
          this.getUnit()
          this.isDescriptionPopup = false
        },
        (error) => {
          this.errorInventory = error.response.data.errors
          this.isLoading = false
        }
      )
    },
    editDescriptionInventory (count) {
      this.isLoading = true
      const data = {
        unitId: this.unitId,
        roomId: this.roomId,
        inventoryId: this.inventoryId,
        name: this.inventoryName,
        description: this.inventoryDescription,
        quantity: count
      }
      this.$store.dispatch('inventory/editInventory', data).then(
        (res) => {
          this.getUnit()
          this.isDescriptionPopup = false
        },
        (error) => {
          this.errorInventory = error.response.data.errors
          this.isLoading = false
        }
      )
    },
    editRooms () {
      this.isLoading = true
      const data = {
        unitId: this.unitId,
        roomId: this.indexEditRoomName,
        name: this.newRoomName
      }
      this.$store.dispatch('inventory/editRooms', data).then(
        (res) => {
          if (this.selectRoom && this.indexEditRoomName >= 0 && this.indexEditRoomName < this.selectRoom.length) {
            this.selectRoom[this.indexEditRoomName] = res.data.name
          }
          this.isEditRoomTitle = false
          this.getUnit()
          this.newRoomName = ''
          this.errorRoom = null
        },
        (error) => {
          this.errorRoom = error.response.data.errors
          this.isLoading = false
        }
      )
    },
    deleteRooms () {
      this.isLoading = true
      const data = {
        unitId: this.unitId,
        roomId: this.roomId
      }
      this.$store.dispatch('inventory/deleteRooms', data).then(
        (res) => {
          this.isModalDeleteRoom = false
          this.getUnit()
        },
        (error) => {
          this.error = error.response.data.errors
          this.isLoading = false
        }
      )
    },
    deleteInventory () {
      this.isLoading = true
      const data = {
        unitId: this.unitId,
        roomId: this.roomId,
        inventoryId: this.inventoryId
      }
      this.$store.dispatch('inventory/deleteInventory', data).then(
        (res) => {
          this.getUnit()
          this.roomId = null
          this.isModalDeleteInventory = false
        },
        (error) => {
          this.error = error.response.data.errors
          this.isLoading = false
        }
      )
    },

    // photo
    addRoomsAttachment () {
      this.isLoading = true
      const API_URL = process.env.VUE_APP_URL
      const formData = new FormData()
      if (this.file) {
        formData.append('attachment', this.file)
      }
      formData.append('title', 'file')
      this.axios.post(API_URL + 'provider/units/' + this.unitId + '/rooms/' + this.roomPhotoId + '/attachments', formData, { headers: authHeader() }).then((res) => {
        this.getUnit()
        this.attachmentRoomId = res.data.id
        this.file = null
        this.isRoomPhotoPopup = false
        this.isLoading = false
      },
      (error) => {
        this.error = error.response.data.errors
        this.errorRoomPhoto = error.response.data.errors
        this.isLoading = false
      }
      )
    },
    addInventoryAttachment () {
      this.isLoading = true
      const API_URL = process.env.VUE_APP_URL
      const formData = new FormData()
      if (this.inventoryFile) {
        formData.append('attachment', this.inventoryFile)
      }
      formData.append('title', 'file')
      this.axios.post(API_URL + 'provider/units/' + this.unitId + '/rooms/' + this.roomPhotoId + '/inventory/' + this.inventoryId + '/attachments', formData, { headers: authHeader() }).then((res) => {
        this.getUnit()
        this.attachmentInventoryId = res.data.id
        this.inventoryFile = null
        this.isInventoryPhotoPopup = false
      },
      (error) => {
        this.error = error.response.data.errors
        this.errorRoomPhoto = error.response.data.errors
        this.isLoading = false
      }
      )
    },
    editRoomsAttachment () {
      this.isLoading = true
      const API_URL = process.env.VUE_APP_URL
      const formData = new FormData()
      if (this.file) {
        formData.append('attachment', this.file)
      }
      formData.append('title', 'file')
      this.axios.post(API_URL + 'provider/units/' + this.unitId + '/rooms/' + this.roomPhotoId + '/attachments/' + this.attachmentRoomId, formData, { headers: authHeader() }).then((res) => {
        this.getUnit()
        this.file = null
        this.isRoomPhotoPopup = false
        this.isLoading = false
      },
      (error) => {
        this.errorRoomPhoto = error.response.data.errors
        this.isLoading = false
      }
      )
    },
    editInventoryAttachment () {
      this.isLoading = true
      const API_URL = process.env.VUE_APP_URL
      const formData = new FormData()
      if (this.inventoryFile) {
        formData.append('attachment', this.inventoryFile)
      }
      formData.append('title', 'file')
      this.axios.post(API_URL + 'provider/units/' + this.unitId + '/rooms/' + this.roomPhotoId + '/inventory/' + this.inventoryId + '/attachments/' + this.attachmentInventoryId, formData, { headers: authHeader() }).then((res) => {
        this.getUnit()
        this.inventoryFile = null
        this.isInventoryPhotoPopup = false
      },
      (error) => {
        this.error = error.response.data.errors
        this.errorRoomPhoto = error.response.data.errors
        this.isLoading = false
      }
      )
    },
    deleteInventoryPhoto () {
      this.isLoading = true
      const data = {
        unitId: this.unitId,
        roomId: this.roomPhotoId,
        inventoryId: this.inventoryId,
        attachmentRoomId: this.attachmentInventoryId
      }
      if (this.attachmentInventoryId) {
        this.$store.dispatch('inventory/deleteInventoryPhoto', data).then(
          (res) => {
            this.getUnit()
            this.imgPopupInventory = null
            this.isPreviewInventoryPhoto = false
          },
          (error) => {
            this.error = error.response.data.errors
            this.isLoading = false
          }
        )
      } else {
        this.isLoading = false
        this.file = null
        this.imgPopupInventory = null
      }
    },
    deleteRoomPhoto () {
      this.isLoading = true
      this.imagePreview = null
      this.isPreview = null
      const data = {
        unitId: this.unitId,
        roomId: this.roomPhotoId,
        attachmentRoomId: this.attachmentRoomId
      }
      if (this.attachmentRoomId) {
        this.$store.dispatch('inventory/deleteRoomPhoto', data).then(
          (res) => {
            this.getUnit()
            this.imgPopupRoom = null
          },
          (error) => {
            this.error = error.response.data.errors
            this.isLoading = false
          }
        )
      } else {
        this.isLoading = false
        this.inventoryFile = null
        this.imgPopupRoom = null
      }
    },
    handleFileUpload () {
      this.file = this.$refs.file.files[0]
      const formData = new FormData()
      formData.append('thumbnail', this.file)
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          this.imagePreview = reader.result
          this.isPreview = true
        }.bind(this),
        false
      )
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file)
        }
      }
    },
    handleInvenrotyFileUpload () {
      this.inventoryFile = this.$refs.inventoryFile.files[0]
      const formData = new FormData()
      formData.append('thumbnail', this.inventoryFile)
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.imageInventoryPreview = reader.result
        this.isPreviewInventoryPhoto = true
      }, false)
      if (this.inventoryFile) {
        if (/\.(jpe?g|png|gif)$/i.test(this.inventoryFile.name)) {
          reader.readAsDataURL(this.inventoryFile)
        }
      }
    },

    // popups
    editRoomPopup (id) {
      this.isEditRoomTitle = true
      this.indexEditRoomName = id
    },
    handleCloseEditRoomTitle () {
      this.isEditRoomTitle = false
      this.errorRoom = null
    },
    openRoomPhotoPopup (id, attachmentId, img) {
      this.isRoomPhotoPopup = true
      this.isPreview = false
      this.roomPhotoId = id
      this.attachmentRoomId = attachmentId
      this.imgPopupRoom = img
    },
    openInventoryPhotoPopup (id, inventoryId, img, attachmentInventoryId) {
      this.isInventoryPhotoPopup = true
      this.isPreviewInventoryPhoto = false
      this.roomPhotoId = id
      this.inventoryId = inventoryId
      this.imgPopupInventory = img
      this.attachmentInventoryId = attachmentInventoryId
    },
    handleCloseInventoryPhotoPopup () {
      this.isInventoryPhotoPopup = false
      this.imageInventoryPreview = null
      this.errorRoomPhoto = null
    },
    handleCloseRoomPhotoPopup () {
      this.isRoomPhotoPopup = false
      this.errorRoomPhoto = null
    },
    addNewInventoryPopup (roomId) {
      this.isNewInventoryPopup = true
      this.otherInventoryId = roomId
    },
    openDescriptionPopup (id, inventoryId, name) {
      this.isDescriptionPopup = true
      this.roomId = id
      this.inventoryId = inventoryId
      this.inventoryName = name
      this.inventoryDescription = ''
    },
    handleCloseDescriptionPopup () {
      this.isDescriptionPopup = false
    },
    handleCloseInventoryPopup () {
      this.isNewInventoryPopup = false
    },
    saveRoomPhoto () {
      if (this.file !== null) {
        if (this.attachmentRoomId === undefined) {
          this.addRoomsAttachment()
        } else this.editRoomsAttachment()
      } else this.isRoomPhotoPopup = false
      this.this.errorRoomPhoto = null
    },
    saveInventoryPhotoPopup () {
      if (this.inventoryFile !== null) {
        if (this.attachmentInventoryId === undefined) {
          this.addInventoryAttachment()
        } else this.editInventoryAttachment()
      }
      this.errorRoomPhoto = null
    },
    openModalDeleteRoom (id) {
      this.isModalDeleteRoom = true
      this.roomId = id
    },
    handleCloseDeleteRoomModal () {
      this.isModalDeleteRoom = false
    },
    handleCloseDeleteInventoryModal () {
      this.isModalDeleteInventory = false
    },
    openPopupDeleteInventory (id, inventoryId) {
      this.isModalDeleteInventory = true
      this.roomId = id
      this.inventoryId = inventoryId
    },
    addNewRoomPopup () {
      this.isAddOtherRoomPopup = true
    },
    handleCloseAddOtherRoomPopup () {
      this.isAddOtherRoomPopup = false
    },

    setPropertiesStepThree () {
      this.$router.push(`/dashboard/create-unit-step-four?buildingId=${this.buildingId}&unitId=${this.unitId}`)
    }
  }
})
