import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-457bbdd5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup" }
const _hoisted_2 = { class: "popup__modal" }
const _hoisted_3 = { class: "popup__modal-text" }
const _hoisted_4 = { class: "popup__modal-buttons" }
const _hoisted_5 = { class: "popup__modal" }
const _hoisted_6 = { class: "popup__modal-text" }
const _hoisted_7 = { class: "popup__modal-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DefaultPopup = _resolveComponent("DefaultPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isModalDeleteInventory)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 0,
          "on-close": _ctx.handleCloseDeleteInventoryModal
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('message.are_you_sure')), 1),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCloseDeleteInventoryModal && _ctx.handleCloseDeleteInventoryModal(...args))),
                  class: "popup__modal-button third-button"
                }, _toDisplayString(_ctx.$t('message.cancel')), 1),
                _createElementVNode("div", {
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.deleteInventory && _ctx.deleteInventory(...args))),
                  class: "popup__modal-button second-button"
                }, _toDisplayString(_ctx.$t('message.delete')), 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true),
    (_ctx.isModalDeleteRoom)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 1,
          "on-close": _ctx.handleCloseDeleteRoomModal
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('message.are_you_sure')), 1),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", {
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleCloseDeleteRoomModal && _ctx.handleCloseDeleteRoomModal(...args))),
                  class: "popup__modal-button third-button"
                }, _toDisplayString(_ctx.$t('message.cancel')), 1),
                _createElementVNode("div", {
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.deleteRooms && _ctx.deleteRooms(...args))),
                  class: "popup__modal-button second-button"
                }, _toDisplayString(_ctx.$t('message.delete')), 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true)
  ]))
}