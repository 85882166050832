
import { defineComponent } from 'vue'
import DefaultPopup from '../DefaultPopup.vue'

export default defineComponent({
  name: 'Popup',
  data () {
    return {
    }
  },
  props: {
    isModalDeleteInventory: {
      type: Boolean,
      default: false
    },
    isModalDeleteRoom: {
      type: Boolean,
      default: false
    },
    handleCloseDeleteInventoryModal: {
      type: Function,
      default: () => false
    },
    handleCloseDeleteRoomModal: {
      type: Function,
      default: () => false
    },
    deleteInventory: {
      type: Function,
      default: () => false
    },
    deleteRooms: {
      type: Function,
      default: () => false
    }
  },
  components: {
    DefaultPopup
  },
  methods: {
  }
})
