
import { defineComponent } from 'vue'
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'
import CreateUnitStepInventorySection from '@/components/sections/dashboard/property/CreateUnitStepInventorySection.vue'
import Main from '@/templates/Main.vue'

export default defineComponent({
  name: 'CreateUnitStepInventory',
  components: {
    Main,
    CreateUnitStepInventorySection
  }
})
